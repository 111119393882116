
.font_h1 {font-size: 2rem;
font-family: 'Noto Sans', sans-serif;
font-weight: lighter;}

.font_h2 {font-size: 1.5rem;
font-family: 'Noto Sans', sans-serif;
font-weight: 600;}

.font_h3 {font-size: 1.17rem;
font-family: 'Noto Sans', sans-serif;
font-weight: normal;}

.font_h4 {font-size: 1.25rem;
font-family: 'Noto Sans', sans-serif;
font-weight: normal;}

.font_h5 {font-size: 0.83rem;
font-family: 'Noto Sans', sans-serif;
font-weight: normal;}

.font_h6 {font-size: 0.625rem;
font-family: 'Noto Sans', sans-serif;}

.font_body {font-size: 1.6rem;
font-family: 'Noto Sans', sans-serif;}

.font_subtitle {font-size: 1.4rem;
font-family: 'Noto Sans', sans-serif;}

.font_button {font-size: 0.75rem;
font-family: 'Noto Sans', sans-serif;}

.font_caption {font-size: 1.2rem;
font-family: 'Noto Sans', sans-serif;}

.font_overline {font-size: 1rem;
font-family: 'Noto Sans', sans-serif;}

.color_primary {background: #000;
color: #fff;}

.color_secondary {background: #000;
color: #fff;}

.color_button {background: linear-gradient(to right,#00ff17, #deff3d);
color: #000;}

.color_title {background: transparent;
color: #000000;}

.page_color {background: #000;
color: #fff;}

.color_active {background: transparent;
color: #00ff17;}

.contact_front_d {background: linear-gradient(0deg,#00ff17, #deff3d);
color: #000;
border: 0;
border-radius: 10px 10px 10px 10px;}

.contact_back_d .list-group {background: #101010;
border: 0;
border-radius: .5rem;
padding: .25rem 0 1rem;}

.contact_back_d .contact_back_d_header {background: linear-gradient(90deg, rgba(239,207,151,1) 0%, RGBA(250, 239, 210, 1) 100%);
color: rgba(149, 101, 39, 1);}

.contact_back_d .contact_back_d_item {background: #101010;
color: #fff;
border-top: 0px solid ;
border-bottom: 0px solid ;
border-left: 0px solid ;
border-right: 0px solid ;
padding: 0.75rem 1rem 0;}

.contact_back_d .contact_back_d_item a {background: #232323;
border-radius: 0px;
filter: drop-shadow(4px 4px 8px black);
padding: 10px;}

.card.contact_back_d>.list-group:first-child {border-radius: 0;
border-top: 3px solid #008b17;}

.announcement_body {color: #fff;}

.announcement_header {padding: 1rem;}

.announcement_header img {filter: drop-shadow(1px 2px 0px black);}

.announcement_header_title {color: #00ff17;}

.announcement_btnClose {background: linear-gradient(to right,#00ff17, #deff3d);
color: #000;
border-radius: 25px 25px 25px 25px;
border-top: 0px solid ;
border-bottom: 0px solid ;
border-left: 0px solid ;
border-right: 0px solid ;}

.header_topBar {background: #000;
color: #fff;
box-shadow: 1px 0px 3px 0px rgba(255,255,255,.3);}

.header_menu {color: rgba(255,255,255,.5);}

.header_menu .header_menu_page .header_menu_content.active {background: transparent;
color: #00ff17;
border-bottom: 3px solid #00ff17;}

.banking_dropdown a li,.claim_dropdown li {background: transparent;
color: rgba(255,255,255,1);
border-radius: 25px;}

.banking_dropdown a.active li,.claim_dropdown a.active li {background: #232323;
color: #fff;
filter: drop-shadow(4px 4px 8px black);}

.btn_banking .banking_dropdown, .btn_claim .claim_dropdown {background: #101010;
filter: drop-shadow(0px 0px 2px #00ff17);}

.header_btnLogin {background: #101010;
color: #fff;
border-radius: 25px 25px 25px 25px;
border-top: 1px solid #00ff17;
border-bottom: 1px solid #00ff17;
border-left: 1px solid #00ff17;
border-right: 1px solid #00ff17;}

.header_btnRegister {background: red;
color: #ffffff;
border-radius: 25px 25px 25px 25px;}

.header_btnLogout {background: red;
color: #ffffff;
border-radius: 25px 25px 25px 25px;}

.header_btnRestore {background: red;
color: #fff;
border-radius: 25px 25px 25px 25px;}

.header_btnBanking {background: #101010;
color: #fff;
border-radius: 25px 25px 25px 25px;
border-top: 1px solid #00ff17;
border-bottom: 1px solid #00ff17;
border-left: 1px solid #00ff17;
border-right: 1px solid #00ff17;}

.header_btnPromoClaim {background: #00ff17;
color: #000;
border-radius: 25px 25px 25px 25px;}

.header_topBar+.header_banking_info {background: #101010;
color: #fff;
box-shadow: inset 0px 0px 5px 0px rgb(0 0 0 / 50%);}

.header_sideBar_topBar {background: #000;
color: #fff;
filter: drop-shadow(-3px 0px 2px rgba(255,255,255,.3));}

.navbar-toggler {background: transparent;
color: #fff;}

.header_sideBar .header_sideBar_body {background: #101010;
color: #fff;}

.header_sideBar_menu .header_menu_content, .header_sideBar_menu summary.header_menu_content+.sidebar_language_selection p:last-child {background: transparent;
color: #fff;
border-bottom: 1px solid #666;
margin: 0 .85rem;}

.header_sideBar_menu .header_menu_content.active {background: transparent;
color: #00ff17;
border-bottom: 1px solid #666;}

.header_banking_info .header_banking_menu_list {color: #999;}

.header_banking_info .header_banking_menu_list .active {color: #fff;
filter: drop-shadow(4px 4px 8px black);}

.header_topBar_wrap .header_banking_info .header_balance {background: #232323;
color: #fff;}

.header_banking_info .header_balance_icon {color: #fff;}

#promoClaimModal label {color: #000;}

#promoClaimModal .filterTab .filterTab_selection.active {background: #00ff17;
color: #000;}

.btn_gamewallet .game_wallet_dropdown {background: #101010;
color: #fff;
filter: drop-shadow(0px 0px 2px #00ff17);}

.game_wallet_dropdown table tbody tr {background: #333333;
color: #fff;
filter: drop-shadow(4px 4px 8px black);}

.btn_gamewallet .game_wallet_dropdown:after {border-bottom: 8px solid #101010;}

.btn_banking .banking_dropdown:after,.btn_claim .claim_dropdown:after {border-bottom: 8px solid #101010;}

#gameWalletModal .modal-body {background: #101010;
color: #fff;
filter: drop-shadow(0px 0px 2px #00ff17);}

#gameWalletModal .game_wallet_dropdown table tbody tr {background: #333333;
color: #fff;
filter: drop-shadow(4px 4px 8px black);}

.footer {background: #101010;
color: #fff;
box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 50%);}

.footer_menu_wrap {border-bottom: 1px dashed #fff;}

.footer_menu_wrap a {color: #00ff17;}

.footer_payment_method .footer_info_img li:first-of-type img {filter: brightness(0) invert(1);}

.btnCancel {background: transparent;
color: #000;
border: 1px solid #00ff17;
border-radius: 5px 5px 5px 5px;}

.modal .confirm_button {background: linear-gradient(to right,#00ff17, #deff3d);
color: #000;
border-radius: .375rem;
padding: .375rem .75rem;}

.modal .cancel_button {background: transparent;
color: #000;
border: 1px solid #00ff17;
border-radius: .375rem;
padding: .375rem .75rem;}

.tab_pills_color.nav-pills .nav-link:hover {color: #00ff17;}

.footer_help a:hover, .header_banking_menu_list a:hover, .header_menu_right .header_menu_page:hover, .walletBar_selection:hover, .banking_dropdown a li:hover,.header_menu_page .header_text:hover,.claim_dropdown li:hover {color: #00ff17;}

.header_menu_page .header_text:hover {color: #00ff17;}

.banking_dropdown a li:hover,.claim_dropdown li:hover,.header_menu_dropdownItem:hover,.header_menu_content.header_menu_dropdownItem.active {background: #232323;
color: #fff;}

.scrollToTopButton {background: #00ff17;
color: #000;
border-radius: 50%;
padding: .375rem .75rem;}

.swiper .swiper-pagination-bullet {background: #00ff17;}

.alertMessage_content svg {color: #00ff17;}

.form_required_input,.register_error_msg,.forgotPassword_error_msg,.forgotUsername_error_msg,.error_msg,.form_required {color: red;}

.btnCancelPromotion {background: #00ff17;
color: #000;}

.btnAfterVerify {background: linear-gradient(to right,#00ff17, #deff3d);
color: #000;
border: 1px solid #00ff17;}

.announcement_header_title span {margin-bottom: .5rem;}

.btnSendCode,.register_btnVerify,.forgotUsername_btnVerify,.forgotPassword_btnVerify {border-radius: 20px;}

.header_logo img {height: 3.5rem;}

.gameWallet_accordion .gameWallet_accordion_icon {background: #00ff17;
color: #000;}

.gameWallet_accordion .accordion-button {background: #232323;
color: #fff;}

.header_bottom_row {color: #fff;}

.selectProviderContainer .game_wallet_dropdown {background: #101010;
color: #fff;
filter: drop-shadow(0px 0px 2px #00ff17);}

.selectProviderContainer .game_wallet_dropdown {background: #101010;
color: #fff;
filter: drop-shadow(0px 0px 2px #00ff17);}

.selectProviderContainer .game_wallet_dropdown:after {border-bottom: 8px solid #101010;}

.header_point span:nth-child(2) {color: #fff;}

.header_topBar_wrap .header_bottom_row .header_balance.coupon_balance {background: #101010;
color: #fff;}

.more_menu .dropdown_more_content {background: #101010;
border-radius: .5rem;
filter: drop-shadow(0 0 2px #00ff17);}

.dropdown_more_content_container a {filter: none;}

.dropdown_more_content a:not(:first-child) {margin-top: .5rem;}

.header_menu_dropdownItem {background: transparent;
color: #fff;
border-radius: 25px;}
